<template>
    <v-app>
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
                <v-col style="margin-top: 0px" cols="12" sm="8" md="8">
                    <v-card class="opa elevation-12 ">
                        <v-card-title>Mentions Légales</v-card-title>
                        <v-container>
                            <v-row justify="center">
                                <v-col cols="12" md="8">
                                    <v-card-text>
                                        <div class="text-subtitle-1">Éditeur de la Plateforme</div>
                                        <p>La plateforme Cartovia est éditée par la société CartoviaTech, SAS au
                                            capital de 10 000 €, dont le siège social est situé au 123 Rue de
                                            l'Innovation, 75000 Paris, France, immatriculée au Registre du Commerce
                                            et des Sociétés de Paris sous le numéro 123 456 789.</p>

                                        <div class="text-subtitle-1">Directeur de la publication</div>
                                        <p>Le Directeur de la publication est M. Carto Vial, joignable à l'adresse
                                            email suivante : contact@cartovia.com.</p>

                                        <div class="text-subtitle-1">Hébergement</div>
                                        <p>Le site est hébergé par HostTech, 456 Avenue du Cloud, 92000 Nanterre,
                                            France. Téléphone : +33 1 02 03 04 05.</p>

                                        <div class="text-subtitle-1">Protection des données personnelles</div>
                                        <p>Cartovia s'engage à protéger les données personnelles et la vie privée de
                                            ses utilisateurs. Les informations recueillies sont traitées
                                            conformément à la réglementation en vigueur. Pour toute information ou
                                            exercice de vos droits Informatique et Libertés sur les traitements de
                                            données personnelles gérés par Cartovia, vous pouvez contacter son
                                            délégué à la protection des données (DPO) à l'adresse suivante :
                                            dpo@cartovia.com.</p>

                                        <div class="text-subtitle-1">Propriété intellectuelle</div>
                                        <p>Tous les éléments de ce site, y compris les textes, graphismes, logos,
                                            sons, photos et animations, sont la propriété de Cartovia, à l'exception
                                            des marques, logos ou contenus appartenant à d'autres sociétés
                                            partenaires ou auteurs. Ces contenus sont protégés par la législation
                                            française et internationale du droit d'auteur et de la propriété
                                            intellectuelle. Toute reproduction, représentation, publication,
                                            transmission ou, plus généralement, toute exploitation non autorisée du
                                            site et/ou de ses éléments engage la responsabilité de l'utilisateur et
                                            est susceptible d'entraîner des poursuites judiciaires, notamment pour
                                            contrefaçon.</p>

                                        <div class="text-subtitle-1">Crédits</div>
                                        <p>Les crédits photos, illustrations, images sont la propriété de Cartovia
                                            ou ont été acquis auprès de sources libres de droits ou de leurs auteurs
                                            respectifs.</p>

                                        <div class="text-subtitle-1">Modification des mentions légales</div>
                                        <p>Cartovia se réserve le droit de modifier les présentes mentions à tout
                                            moment. L'utilisateur s'engage donc à les consulter régulièrement.</p>

                                        <div class="text-subtitle-1">Droit applicable</div>
                                        <p>Le présent site est soumis au droit français et en cas de litige, les
                                            tribunaux français seront seuls compétents.</p>
                                    </v-card-text>
                                </v-col>
                            </v-row>
                        </v-container>

                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>


<script>
import xhr from "../plugins/axios";

export default {
    name: "Faq",
    data() {
        return {
            items: [
                {
                    title: 'Groupe 1',
                    active: false,
                    questions: [
                        {
                            question: 'Question 1.1?',
                            answer: 'Réponse à la question 1.1.'
                        },
                        {
                            question: 'Question 1.2?',
                            answer: 'Réponse à la question 1.2.'
                        },
                        {
                            question: 'Question 1.3?',
                            answer: 'Réponse à la question 1.3.'
                        },
                    ]
                },
                {
                    title: 'Groupe 2',
                    active: false,
                    questions: [
                        {
                            question: 'Question 2.1?',
                            answer: 'Réponse à la question 2.1.'
                        },
                        {
                            question: 'Question 2.2?',
                            answer: 'Réponse à la question 2.2.'
                        },
                        {
                            question: 'Question 2.3?',
                            answer: 'Réponse à la question 2.3.'
                        },
                    ]
                },
                // Ajoutez d'autres groupes et questions si nécessaire
            ]
        }
    },
    methods: {

    }
};
</script>